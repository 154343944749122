import * as React from 'react'
import { graphql } from 'gatsby'

import { IImg, Image } from '../image'

import { AuthorWrapper, AuthorContent, ImageWrapper, ContentWrapper } from './styles'
import { RichText } from '../rich-text'

export interface IAuthor {
  name: string
  title: string
  photo?: IImg
  bio?: any
}

export const Author = (author: IAuthor) => {
  return (
    <AuthorWrapper data-testid={`author-${author.name}`}>
      <AuthorContent>
        {author.photo && (
          <ImageWrapper>
            <Image {...author.photo} />
          </ImageWrapper>
        )}
        <ContentWrapper>
          <h4>{author.name}</h4>
          <h5>{author.title}</h5>
          <RichText {...author.bio} />
        </ContentWrapper>
      </AuthorContent>
    </AuthorWrapper>
  )
}

export const query = graphql`
  fragment AuthorContent on ContentfulAuthor {
    name
    title
    photo {
      url
      alt
    }
    bio { 
      raw
    }
  }
`
