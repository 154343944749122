import tw from 'twin.macro'

export const Section = tw.section`
  bg-primary-block border-b py-8
`

export const SectionContent = tw.div`
  container max-w-5xl mx-auto
`

export const ContentWrapper = tw.div`
  m-4
`

export const BodyHeader = tw.h1`
  w-full my-2 text-5xl font-heading leading-tight text-center text-primary-text
`

export const Underline = tw.div`
  w-full mb-12 mt-6
  [> div]:(h-1 mx-auto w-80 my-0 py-0 rounded-t bg-gradient-to-r from-primary to-primary-fade)
`
