import * as React from 'react'
import { graphql } from 'gatsby'

import { IBaseProps } from './'

import { Slots } from '../sections/slots'
import { Body } from '../sections/body'
import { PageLayout } from '../components/page-layout'
import { Banner, IBanner } from '../components/banner'
import { RichText } from '../components/rich-text'
import { Author, IAuthor } from '../components/author'

export interface IPage {
  id: string
  slug: string
  banner: IBanner
  title: string
  body?: any
  slots: any[]
  pageType: string
  author: IAuthor
  displayInNavigation: boolean
}

export const Page = (props: IBaseProps) => {
  const { page, contentfulPage, allContentfulPage, allFooterNavigation, contentfulSiteSettings } = props.data
  const footerNavItems = allFooterNavigation.nodes
  const { metaTitlePrefix, headerCta } = contentfulSiteSettings

  return (
    <PageLayout
      title={page.title}
      footerNavItems={footerNavItems}
      navItems={allContentfulPage.nodes}
      metaTitlePrefix={metaTitlePrefix}
      headerCta={headerCta}
      currentPage={page.slug}
    >
      { page.banner && <Banner {...page.banner} /> }
      <Body title={page.title}>
        {page.author && <Author {...page.author} />}
        <RichText {...contentfulPage.body} />
      </Body>
      <Slots slots={page.slots} />
    </PageLayout>
  )
}

export default Page

export const query = graphql`
  fragment PageContent on Page {
    id
    slug
    banner {
      ...BannerContent
    }
    title
    slots {
      ... on ContentfulCardContainer {
        ...CardContainerContent
      }
      ... on ContentfulArticleContainer {
        ...ArticleContainerContent
      }
      ... on ContentfulWidget {
        ...WidgetContainerContent
      }
    }
    pageType
    author {
      ...AuthorContent
    }
    displayInNavigation
  }

  query AllPageContent($id: String!) {
    contentfulSiteSettings {
      headerCta {
        ...LinkContent
      }
      metaTitlePrefix
    }
    
    page(id: { eq: $id }) {
      ...PageContent
    }

    contentfulPage(id: { eq: $id }) {
      body {
        raw
        references {
          contentful_id
          url
          alt
        }
      }
    }

    allContentfulPage {
      nodes {
        ...PageContent
      }
    }

    allFooterNavigation {
      nodes {
        ...FooterNavigationContent
      }
    }
  }
`
