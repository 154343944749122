import * as React from 'react'
import { graphql } from 'gatsby'

import { ILink } from '../link'
import { BannerWrapper, BannerText } from './styles'
import { IImg } from '../image'

export interface IBanner {
  image: IImg
  bannerText: string
  link: ILink
}

export const Banner = ({ image, bannerText, link }: IBanner) => (
  <BannerWrapper style={{ backgroundImage: `url(${image.url})` }} data-testid="banner">
    {bannerText && <BannerText>{bannerText}</BannerText>}
  </BannerWrapper>
)

export const query = graphql`
  fragment BannerContent on Banner {
    id
    image {
      url
      alt
    }
    bannerText
    link {
      page {
        slug
      }
      title
      url
    }
  }
`

