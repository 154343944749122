import * as React from 'react'

import {
  Section,
  SectionContent,
  ContentWrapper,
  BodyHeader,
  Underline
} from './styles'

interface IBody {
  title?: string,
  children?: React.ReactNode
}

export const Body = ({ title, children }: IBody) => {
  return (
    <Section>
      <SectionContent>
        <ContentWrapper>
          {title && (
            <>
              <BodyHeader>{title}</BodyHeader>
              <Underline><div /></Underline>
            </>
          )}

          {children}
        </ContentWrapper>
      </SectionContent>
    </Section>
  )
}