import tw from 'twin.macro'

export const AuthorWrapper = tw.div`
  lg:float-right w-full lg:w-1/3 box-border flex
`

export const AuthorContent = tw.div`
  bg-primary-block rounded overflow-hidden shadow p-2 lg:m-4 lg:mt-0
`

export const ContentWrapper = tw.div`
  [h4, h5]:(font-heading m-2)
  [h4]:(text-xl)
  [p]:(font-body text-sm m-2)
`

export const ImageWrapper = tw.div`
  w-1/4 float-left p-2
  [img]:(max-w-full)
`


